<template>
	<div class="wrapperContent">
		<div class="wrapperUpperFold">
			<div class="upperFoldContent"
				v-rellax="rellax"
				data-rellax-speed="-0.5">
				<section>
					<div class="lg:grid-cols-2 lg:pb-0 lg:gap-4 md:grid-cols-2 m-auto grid pb-5 gap-2">
						<div class="flex flex-col justify-center flex-row">
							<h1 class="lg:text-left md:text-left text-center" v-html="$t('strategyMsg')"></h1>
							<p class="lg:text-xl lg:pb-5 lg:text-left md:text-left text-center text-base pb-5" v-html="$t('strategyMsgDesc')"></p>
							<div class="lg:flex lg:w-full lg:justify-start lg:pb-0 pb-5 md:justify-start md:w-full justify-center grid grid-cols-2 gap-2 w-10/12 ml-auto mr-auto">
								<div class="lg:flex-initial">
									<a :href="apkLink" target="_blank">
										<img src="@/assets/images/apk.png" srcset="@/assets/images/apk.png 1x, @/assets/images/apk@2x.png 2x" alt="Google Play" />
									</a>
								</div>
								<div class="lg:flex-initial">
									<a :href="iosLink" target="_blank">
										<img src="@/assets/images/ios.png" srcset="@/assets/images/ios.png 1x, @/assets/images/ios@2x.png 2x" alt="App Store" />
									</a>
								</div>
							</div>
						</div>
						<div class="flex flex-col justify-center lg:w-full w-10/12 m-auto">
							<img class="lg:w-full sm:w-full xs:w-full w-4/6 m-auto" src="@/assets/images/whaledoo_app.svg" srcset="@/assets/images/whaledoo_app.svg 1x, @/assets/images/whaledoo_app@2x.svg 2x" alt="Whaledoo will do it for you" />
						</div>
					</div>
				</section>
			</div>
			<div class="wrapperExchangeCenter"
				v-rellax="rellax"
				data-rellax-speed="0.5">
				<ul class="flex justify-center">
					<li class="flex-initial" v-for="exchangeCenter in exchangeCenters" :key="exchangeCenter.id">
						<a :href="exchangeCenter.url" target="_blank">
							<img :src="exchangeCenter.img" :alt="exchangeCenter.name" :title="exchangeCenter.name" />
						</a>
					</li>
				</ul>
			</div>
			<div class="upperFoldBg">
				<bgUpperFold />
			</div>
		</div>

		<div class="wrapperContentSection">
			<section>
				<div class="lg:w-10/12 lg:grid lg:grid-cols-10 lg:gap-4 pb-5 m-auto w-12/12 flex flex-col">
					<div class="lg:col-span-4 lg:w-full m-auto w-10/12"
						v-rellax="rellax"
						data-rellax-speed="-0.5"
						data-rellax-xs-speed="0.5">
						<img class="lg:w-full md:w-8/12 w-full m-auto" src="@/assets/images/what_is_whaledoo.svg" :alt="$t('stgIntro_title_1')" />
					</div>
					<div class="lg:pt-0 pt-5 col-span-6 flex flex-col justify-center">
						<h4 class="lg:text-left lg:pb-0 lg:pt-0 pt-5 pb-2 text-center">{{$t('stgIntro_title_1')}}</h4>
						<p class="lg:text-left text-center pb-5" v-html="$t('stgIntro_dec_1')"></p>
					</div>
				</div>

				<div class="lg:w-10/12 lg:grid lg:grid-cols-10 lg:gap-4 lg:pb-0 pb-5 m-auto w-12/12 flex flex-col rtl">
					<div class="lg:col-span-4 lg:w-full m-auto w-10/12"
						v-rellax="rellax"
						data-rellax-speed="-0.5"
						data-rellax-xs-speed="-0.3">
						<img class="lg:w-full md:w-8/12 w-full m-auto" src="@/assets/images/why_whaledoo.svg" alt="Why Whaledoo" />
					</div>
					<div class="lg:pt-0 pt-5 ltr col-span-6 flex flex-col justify-center">
						<h4 class="lg:text-left lg:pb-0 lg:pt-0 pt-10 pb-2 text-center">{{$t('stgIntro_title_2')}}</h4>
						<p class="lg:text-left text-center" v-html="$t('stgIntro_dec_2')"></p>
					</div>
				</div>
			</section>
		</div>
		<div class="wrapperContentSection stepsSection">
			<section>
				<h3 class="lg:text-left text-center"
					v-rellax="rellax"
					data-rellax-speed="-0.2"
					data-rellax-xs-speed="0">{{$t('journey_title')}}</h3>
				<div class="wrapperJourneySteps"
					v-rellax="rellax"
					data-rellax-speed="-0.2"
					data-rellax-xs-speed="0">
					<div class="journeySteps lg:w-11/12 w-full m-auto">
						<ul class="lg:grid lg:grid-cols-10 lg:gap-4 lg:gap-y-6 gap-4 grid grid-cols-2 w-12/12 m-auto">
							<li class="grid lg:col-span-2">
								<div class="stepContent step-1">
									<a class="flex w-full h-full" href="javascript:;" @click="openExchange">
										<div class="neuBlock whiteBlock stepBlock lg:text-base text-sm">
											{{$t('journey_step1')}}
										</div>
									</a>
								</div>
							</li>
							<li class="lg:grid col-span-1 hidden">
								<div class="stepContent">
									<div class="journeyLine">
										<div class="jlStraight jlArrow"></div>
									</div>
								</div>
							</li>
							<li class="grid lg:col-span-2">
								<div class="stepContent step-2">
									<div class="neuBlock whiteBlock stepBlock lg:text-base text-sm">
										{{$t('journey_step2')}}
									</div>
								</div>
							</li>
							<li class="lg:grid col-span-1 hidden">
								<div class="stepContent">
									<div class="journeyLine">
										<div class="jlStraight jlArrow"></div>
									</div>
								</div>
							</li>
							<li class="grid lg:col-span-2">
								<div class="stepContent step-3">
									<div class="neuBlock whiteBlock stepBlock lg:text-base text-sm">
										{{$t('journey_step3')}}
									</div>
								</div>
							</li>
							<li class="lg:grid col-end-10 col-span-1 hidden">
								<div class="stepContent">
									<div class="journeyLine jlSideEnd">
										<div class="jlStraight jlFull"></div>
									</div>
								</div>
							</li>
							<!-- next row !-->
							<li class="lg:grid col-start-2 col-end-10 col-span-8 hidden">
								<div class="stepContent">
									<div class="journeyLine">
										<div class="jlStraight jlFull"></div>
									</div>
								</div>
							</li>
							<!-- next row !-->
							<li class="lg:grid col-start-2 col-span-1 hidden">
								<div class="stepContent">
									<div class="journeyLine jlSideStart">
										<div class="jlStraight jlArrow"></div>
									</div>
								</div>
							</li>
							<li class="grid lg:col-span-2">
								<div class="stepContent step-4">
									<div class="neuBlock whiteBlock stepBlock lg:text-base text-sm">
										{{$t('journey_step4')}}
									</div>
								</div>
							</li>
							<li class="lg:grid col-span-1 hidden">
								<div class="stepContent">
									<div class="journeyLine">
										<div class="jlStraight jlArrow"></div>
									</div>
								</div>
							</li>
							<li class="grid lg:col-span-2">
								<div class="stepContent step-5">
									<div class="neuBlock whiteBlock stepBlock lg:text-base text-sm">
										{{$t('journey_step5')}}
									</div>
								</div>
							</li>
							<li class="lg:grid col-span-1 hidden">
								<div class="stepContent">
									<div class="journeyLine">
										<div class="jlStraight jlArrow"></div>
									</div>
								</div>
							</li>
							<li class="grid lg:col-span-2">
								<div class="stepContent step-6">
									<div class="neuBlock whiteBlock stepBlock lg:text-base text-sm">
										{{$t('journey_step6')}}
									</div>
								</div>
							</li>
						</ul>
					</div>

				</div>
			</section>
		</div>
		<div class="wrapperContentSection whiteSection">
			<section>
				<h3 class="text-center">{{$t('portfolio_title')}}</h3>
				<p class="lg:text-xl lg:pb-5 text-center text-base pb-5" v-html="$t('portfolio_desc')"></p>
				<div class="flex flex-col text-center lg:w-8/12 w-12/12 m-auto">
					<small>{{$t('portfolio_date')}}</small>
					<div class="portfolios block w-full m-auto relative">
						<img src="@/assets/images/whaledoo_portfolio.png" alt="Whaledoo Portfolio">
						<div class="ctaPorfolio lg:w-4/12 w-6/12 m-auto">
							<div class="btn-secondary lg:flex-initial">
								<a :href="appUrl" target="_blank">{{$t('callToAction')}}</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="wrapperContentSection extSection" id="performanceReport">
			<section>
				<div class="lg:w-full lg:grid lg:grid-cols-12 lg:gap-2 lg:pb-0 w-full pb-5 m-auto w-full flex flex-col">
					<div class="lg:col-span-3 lg:w-full lg:text-left m-auto text-center">
						<h3>{{$t('performanceReport')}}</h3>
					</div>
					<div class="lg:w-full lg:col-span-9 lg:pt-0 lg:w-10/12 pt-5 w-full m-auto">
						<CoolLightBox 
							:items="galleries" 
							:index="index"
							:closeOnClickOutsideMobile="true"
							:effect="'fade'"

							@on-open="lbOpen"
							@close="lbClose"
						></CoolLightBox>
						<ul class="lg:grid lg:grid-cols-3 flex flex-wrap w-full gap-4">
							<li class="col-span-1 cursor-pointer lg:pb-0 pb-5" v-for="(thumb, imageIndex) in galleries" :key="imageIndex" @click="index = imageIndex">
								<div class="rounded-lg overflow-hidden w-full h-48">
									<img :src="thumb.src" class="w-full h-auto" :alt="thumb.description" />
								</div>
								<p class="leading-6 pt-2 lg:text-left text-center">{{thumb.description}}</p>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import bgUpperFold from '@/assets/images/bg_upperfold.svg?inline';

import Vue from 'vue'
import VueRellax from 'vue-rellax'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

Vue.use(VueRellax, CoolLightBox)

export default {
	name: 'Strategy',
	props: [
		//data
		"exchangeCenters", "exchangeShow",
		"apkLink", "iosLink", "appUrl"
	],
	components: {
		bgUpperFold,
		CoolLightBox
	},
	data() {
		return {
			rellax: {
				speed: -2,
				breakpoints:[640, 768, 1024]
			},
			galleries: [
				{
					description: '2021 Whaledoo Annualized Return Rate',
					src: require('@/assets/images/Whaledoo_chart_2021_annualized.jpg')
				},
				{
					description: "Buy & Hold Strategy VS Whaledoo on Market Crash time 2021 May - July",
					src: require('@/assets/images/Web_Chart_2021_compile_May_July.jpg')
				},
				{
					description: "Buy & Hold Strategy VS Whaledoo on Market Crash time 2021 Nov - Dec",
					src: require('@/assets/images/Web_Chart_2021_compile_Nov_Dec.jpg')
				}
			],
			index: null
		}
	},
	methods: {
		openExchange: function(){
			this.$emit('openExchange',function(){
				return this.promotionShow;
			})
		},
		lbOpen: function(){ this.$parent.$el.children[1].style.zIndex = 11},
		lbClose: function(){
			this.$parent.$el.children[1].style.zIndex = 2,
			this.index = null
		}
	},
	metaInfo: {
		meta: [
			{ name: 'keywords', content: 'whaledoo, crypto, trading, robot, bot, robo'}
		]
	}
}
</script>

import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import i18n from '../i18n'

//import Vuebar from 'vuebar';

import home from '@/router/home'
import strategy from '@/router/strategy'
import affiliate from '@/router/affiliate'
import helpCenter from '@/router/help-center'
import termsAndConditions from '@/router/terms-and-conditions'
import privacyPolicy from '@/router/privacy-policy'

Vue.use(Router)
Vue.use(Meta)

//Vue.use(Vuebar);

import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.use(VueSmoothScroll, {
  duration: 400,
  updateHistory: false,
})

const i8lang = "/:lang/"
export default new Router({
	base: process.env.BASE_URL,
	mode: 'history',
	linkActiveClass: 'selected',

	scrollBehavior (to, from, savedPosition) {
		let position = { x: 0, y: 0, behavior:"smooth"}
		if (savedPosition) {
			position = savedPosition
		}
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(position)
			},0)
		})
	},
	// below scrollbehavior got delay and are still being fixed by vue team
	/*scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0, behavior: 'smooth'}
		}
	},*/
	routes: [
		{ path: '*', redirect: `${i18n.locale}`, props: true},
		{ path: i8lang, name: 'Home', component: home},
		{ path: i8lang + 'strategy', name: 'Strategy', component: strategy},
		{ path: i8lang + 'affiliate', name: 'Affiliate', component: affiliate},
		{ path: i8lang + 'help-center', name: 'Help Center', component: helpCenter},
		{ path: i8lang + 'terms-and-conditions', name: 'Terms And Conditions', component: termsAndConditions},
		{ path: i8lang + 'privacy-policy', name: 'Privacy Policy', component: privacyPolicy}
		/* props unable to pass through children in routes, waiting to revolve */
		/*{
			path: '/:lang',
			props: true,
			component: { render (c) { return c('router-view')}},
			children: [
				{ path: '/', name: 'Home', component: home},
				{ path: 'strategy', name: 'Strategy', component: strategy},
				{ path: 'affiliate', name: 'Affiliate', component: affiliate},
				{ path: 'help-center', name: 'Help Center', component: helpCenter}
			]
		}*/
	],
})

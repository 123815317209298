<template>
  <canvas id="myChart" class=""></canvas>
</template>

<script>
import jQuery from 'jquery'
import Chart from 'chart.js/auto'

export default {
  name: 'BackTestChart',
  components: {},
  props: [
    /*'slideInitial'*/
  ],
  data(){
    return{
      // test,
      jQuery,
      Chart
    }
  },
  created: function(){},
  mounted: function(){
    /* ======================= lx backtest js ======================= */
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    var formatter2 = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    function round(value) {
      return Math.round(value * 100) / 100;
    }

    let initialDeposit = 1500;
    let monthlyDeposit = 0;
    let labels = [];
    let whaledooData = [];
    let industryData = [];
    let myChart;

    jQuery('#backtest').on('input', '#slideInitial', function(){
      let val = jQuery(this).val();
      initialDeposit = val;
      jQuery('#initialValue').html(formatter.format(val));
      setTimeout(function() {
          calculateChart(initialDeposit, monthlyDeposit);
      }, 500);
    });
    jQuery('#backtest').on('input', '#slideMonthly', function(){
      let val = jQuery(this).val();
      monthlyDeposit = val;
      jQuery('#monthlyValue').html(formatter.format(val));
      setTimeout(function() {
          calculateChart(initialDeposit, monthlyDeposit);
      }, 500);
    });

    jQuery('#slideInitial').val(initialDeposit);
    jQuery('#slideMonthly').val(monthlyDeposit);
    jQuery('#initialValue').html(formatter.format(jQuery('#slideInitial').val()))
    jQuery('#monthlyValue').html(formatter.format(jQuery('#slideMonthly').val()))
    calculateChart(initialDeposit, monthlyDeposit);

    const getOrCreateLegendList = (chart, id) => {
      const legendContainer = document.getElementById(id);
      let listContainer = legendContainer.querySelector('ul');

      if (!listContainer) {
          listContainer = document.createElement('ul');
          listContainer.style.display = 'flex';
          listContainer.style.flexDirection = 'column';
          listContainer.style.marginTop = 0;
          listContainer.style.padding = 0;

          legendContainer.appendChild(listContainer);
      }
      return listContainer;
    };

    const htmlLegendPlugin = {
      id: 'htmlLegend',
      afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);

        // Remove old legend items
        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);
        items.reverse();
        items.forEach(item => {
          const li = document.createElement('li');
          li.style.alignItems = 'center';
          li.style.cursor = 'pointer';
          li.style.display = 'flex';
          li.style.flexDirection = 'row';
          li.style.marginTop = '10px';

          li.onclick = () => {
            const {type} = chart.config;
            if (type === 'pie' || type === 'doughnut') {
                // Pie and doughnut charts only have a single dataset and visibility is per item
                chart.toggleDataVisibility(item.index);
            } else {
                chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
            }
            chart.update();
          };

          // Color box
          const boxSpan = document.createElement('span');
          boxSpan.style.background = item.fillStyle;
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = item.lineWidth + 'px';
          boxSpan.style.borderRadius = "100%";
          boxSpan.style.display = 'inline-block';
          boxSpan.style.height = '20px';
          boxSpan.style.marginRight = '10px';
          boxSpan.style.width = '20px';

          // Text
          const textContainer = document.createElement('p');
          textContainer.style.color = item.fontColor;
          textContainer.style.margin = 0;
          textContainer.style.padding = 0;
          textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);

          const balanceContainer = document.createElement('li');
          balanceContainer.style.color = '#2d3f59';
          balanceContainer.style.marginLeft = '30px';
          balanceContainer.style.fontWeight = 'bold';

          let balance;
          if (item.text === 'Whaledoo') {
              balance = document.createTextNode(formatter2.format(whaledooData[19]));
          } else {
              balance = document.createTextNode(formatter2.format(industryData[19]));
          }
          balanceContainer.appendChild(balance);

          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
          ul.appendChild(balanceContainer);
        });
      }
    };

    const getOrCreateTooltip = (chart) => {
      let tooltipEl = document.getElementById('tooltip');
      // let tooltipEl = chart.canvas.parentNode.querySelector('div');
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
      // Tooltip Element
      const {chart, tooltip} = context;
      const tooltipEl = getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
      }

      // Set Text
      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map(b => b.lines);

        const tableHead = document.createElement('thead');

        titleLines.forEach(title => {
          const tr = document.createElement('tr');
          tr.style.borderWidth = 0;

          const th = document.createElement('th');
          th.style.borderWidth = 0;
          const text = document.createTextNode(title);

          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        });

        const tableBody = document.createElement('tbody');
        bodyLines.forEach((body, i) => {
          const colors = tooltip.labelColors[i];

          const span = document.createElement('span');
          span.style.background = colors.backgroundColor;
          span.style.borderColor = colors.borderColor;
          span.style.borderWidth = '2px';
          span.style.marginRight = '10px';
          span.style.height = '10px';
          span.style.width = '10px';
          span.style.display = 'inline-block';

          const tr = document.createElement('tr');
          tr.style.backgroundColor = 'inherit';
          tr.style.borderWidth = 0;

          const td = document.createElement('td');
          td.style.borderWidth = 0;

          const text = document.createTextNode(body);

          td.appendChild(span);
          td.appendChild(text);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }

      const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';
      tooltipEl.style.top = positionY + tooltip.caretY + 'px';
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    };

    function calculateChart(initialDeposit, monthlyDeposit) {
      whaledooData = [];
      industryData = [];
      labels = [];
      initialDeposit = Number(initialDeposit);
      monthlyDeposit = Number(monthlyDeposit);
      for (let i = 0; i <= 19; i++) {
        labels.push("Year " + (i + 1));
        if (i === 0) {
            whaledooData.push(initialDeposit);
            industryData.push(initialDeposit);
        } else {
            const lastIndustryPrice = industryData[i-1];
            const lastWhaledooPrice = whaledooData[i-1];
            // console.log((lastWhaledooPrice * 0.3) + lastWhaledooPrice + monthlyDeposit);
            whaledooData.push(round((lastWhaledooPrice * 0.3) + lastWhaledooPrice + monthlyDeposit));
            industryData.push(round((lastIndustryPrice * 0.11) + lastIndustryPrice + monthlyDeposit));
        }
      }
      if (myChart) {
        myChart.data.labels = labels;
        myChart.data.datasets = [
            {
                label: 'Industry Average',
                backgroundColor: 'rgba(213, 225, 240, 1)',
                pointRadius: 2,
                data: industryData,
                fill: true,
            },
            {
                label: 'Whaledoo',
                data: whaledooData,
                backgroundColor: 'rgba(91, 194, 244, 1)',
                pointRadius: 2,
                fill: true
            },
        ];
        myChart.update();
      }
    }
    
    myChart = new Chart(
      document.getElementById('myChart'),
        {
          type: 'line',
          data: {
            datasets: [
              {
                label: `Industry Average`,
                backgroundColor: 'rgba(213, 225, 240, 1)',
                pointRadius: 2,
                data: industryData,
                fill: true
              },
              {
                label: `Whaledoo`,
                data: whaledooData,
                backgroundColor: 'rgba(91, 194, 244, 1)',
                pointRadius: 2,
                fill: true
              },
            ],
            labels: labels,
          },
          plugins: [htmlLegendPlugin],
          options: {
            // edited
            elements: {
              point: {
                hoverRadius: 8,
              },
            }, // end of edited
            
            interaction: {
              mode: 'index',
              intersect: false,
            },
            plugins: {
              legend: {
                display: false,
              },
              htmlLegend: {
                // ID of the container to put the legend in
                containerID: 'legend-container',
              },
              tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandler
              }
            },
            scales: {
              xAxis: {
                grid:  {
                  display: false,
                },
                ticks: {
                  // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                  callback: function(val, index) {
                    if (index === 0) {
                      return labels[0];
                    }
                    // Hide the label of every 2nd dataset
                    return (index+1) % 5 === 0 ? labels[index] : '';
                  },
                }
              },
              yAxes: {
                position: 'right',
                display: true,
                ticks: {
                  beginAtZero: false,
                },
                grid:  {
                  display: false,
                },
              },
            },
          }
        }
    );
    /* ======================= end of lx backtest js ================ */
  }
}
</script>


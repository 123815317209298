<template>
	<div class="wrapperContent">
		<div class="wrapperContentSection docSection">
			<section>
				<div class="docHeader lg:w-8/12 w-full flex flex-col m-auto">
					<div class="flex flex-col justify-center flex-row">
						<h1 class="text-center">{{$t('tnc')}}</h1>
					</div>
				</div>
			</section>
		</div>
		<div class="wrapperContentSection">
			<section>
				<div class="wrapperTnc block m-auto lg:w-10/12 w-12/12">
					<small>{{$t('tnc_update_time')}}</small>
					<h5>{{$t('tnc_main_title')}}</h5>
					<ul>
						<li v-for="tncList in $t('tnc_listing')" :key="tncList.id">
							<div v-html="tncList.tnc_title"></div>
							<div v-html="tncList.tnc_dec"></div>
						</li>
					</ul>
				</div>
			</section>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Terms_And_Conditions',
	data() {
		return {

		}
	}
}
</script>
<template>
<div class="wrapperPageMenu" :v-if="mobMenuShow">
  <div class="menuPopUp">
    <div class="menuWrapperContent">
      <div class="menuContent">
        <div class="menuListing">
          <ul>
            <li class="pb-5" v-for="mainNav in ($t('mainNavs'))" :key="mainNav.id">
              <div class="btn-primary-dark">
                <router-link class="text-right text-3xl" :to="`/${$i18n.locale}`+ mainNav.url" exact>{{mainNav.pageTitle}}</router-link>
              </div>
            </li>
            <li><div class="btn-primary-dark"><a class="text-right text-3xl" href="https://app.whaledoo.com/" target="_blank">{{$t('callToAction')}}</a></div></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="popMenuClose">X</div>
  <div @click="mobClose" class="overlayClose"></div>
</div>

</template>

<script>

export default {
  name: 'mob-menu',
  props:['mobMenuShow', 'mainNavs'],
  data () {
    return {
    }
  },
  methods: {
    mobClose: function(){
      this.$emit('mobClose', function(){
        //return !mobMenuShow
      });
    }
  },
  
}
</script>
<template>
	<div class="wrapperContent">
		<div class="wrapperUpperFold fullColor">
			<div class="upperFoldContent">
				<section>
					<div class="lg:w-8/12 w-full flex flex-col m-auto pb-5">
						<div class="flex flex-col justify-center flex-row">
							<h1 class="text-center">{{$t('affiliate_header')}}</h1>
							<p class="lg:text-xl lg:pb-5 md:pb-10 text-center text-base pb-5">{{$t('affiliateMsg')}}</p>
						</div>
						<div class="lg:w-8/12 lg:pt-5 w-full flex flex-col pl-5 pr-5 m-auto justify-center"
							v-rellax="rellax"
							data-rellax-speed="-0.5"
							data-rellax-xs-speed="-1.5"
						>
							<img class="lg:w-full md:w-4/6 m-auto" src="@/assets/images/affiliate_programe.svg" :alt="$t('affiliate_header')" />
						</div>
					</div>
				</section>
			</div>
		</div>
		<div class="wrapperContentSection">
			<section>
				<div class="lg:grid lg:grid-cols-2 lg:p-8 w-12/12 m-auto flex flex-col flex-col-reverse gap-4 wrapperAccordion">
					<div class="flex flex-col justify-center">
						<h4 class="lg:text-left lg:pt-0 lg:pb-0 pt-5 pb-2 text-center">{{$t('subFuel_title')}}</h4>
						<p class="lg:text-left text-center" v-html="$t('subFuel_desc')"></p>
						<img class="lg:m-0 w-auto h-auto mx-auto w-full" style="max-width:532px;" src="@/assets/images/comission_rate.png" :alt="$t('subFuel_title')" />
					</div>
					<div class="flex flex-col justify-center relative"
						v-rellax="rellax"
						data-rellax-speed="-0.3"
						data-rellax-xs-speed="-0.3"
					>
						<img class="lg:w-10/12 lg:w-4/6 md:w-5/6 w-full m-auto" src="@/assets/images/subscriptions_fuel.svg" :alt="$t('affiliate_header')" />
					</div>
				</div>
			</section>
		</div>
		<div class="wrapperContentSection slenderSection">
			<section>
				<div class="lg:grid lg:grid-cols-2 lg:p-8 w-12/12 m-auto flex flex-col flex-col-reverse gap-4 wrapperAccordion">
					<div class="flex flex-col justify-center">
						<h4 class="lg:text-left lg:pt-0 lg:pb-0 pt-5 pb-2 text-center">{{$t('subPerformFuel_title')}}</h4>
						<p class="lg:text-left text-center pb-2" v-html="$t('subPerformFuel_desc')"></p>
						<img class="lg:m-0 w-auto h-auto mx-auto w-full" style="max-width:423px;" src="@/assets/images/comission_rate_performance.png" :alt="$t('subPerformFuel_title')" />
					</div>
					<div class="flex flex-col justify-center relative">
						<img class="lg:w-10/12 w-4/6 m-auto" src="@/assets/images/additional_rewards_performance_fuel.svg" :alt="$t('subPerformFuel_title')" />
					</div>
				</div>
			</section>
			<div class="bgSlender top"></div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import VueRellax from 'vue-rellax'

Vue.use(VueRellax)

export default {
	name: 'Affiliate',
	data() {
		return {
			rellax: {
				speed: -2,
				breakpoints:[640, 768, 1024]
			}
		}
	},
	metaInfo: {
		meta: [
			{ name: 'keywords', content: 'whaledoo, crypto, trading, robot, bot, robo'}
		]
	}
}
</script>

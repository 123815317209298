<template>
	<div class="wrapperContent">
		<div class="wrapperContentSection docSection">
			<section>
				<div class="docHeader lg:w-8/12 w-full flex flex-col m-auto">
					<div class="flex flex-col justify-center flex-row">
						<h1 class="text-center">{{$t('helpCenter')}}</h1>
					</div>
				</div>
			</section>
		</div>
		<div class="wrapperContentSection">
			<section>
				<div class="lg:grid lg:grid-cols-10 lg:space-y-0 space-y-4 flex flex-col">
					<div class="docCta grid col-span-3">
						<ul class="flex flex-col text-lg space-y-4">
							<li v-for="(docFaq, index) in $t('docFaqs')" :key="docFaq.id">
								<a :href="'#'+ docFaqs[index].sector" :class="{selected: docFaqs[index].visible, underline: docFaqs[index].visible}" v-smooth-scroll="{ duration: 1000, offset: -60, updateHistory: false }" @click="toggleSector(index)">
									{{docFaq.sector}}
								</a>
							</li>
						</ul>
						<hr class="lg:hidden block my-5"/>
					</div>
					<div class="docDetails grid col-span-7">
						<ul>
							<!-- <li class="pb-5" v-for="(docFaq, index) in docFaqs" :key="docFaq.id">
								<div v-show="docFaqs[index].visible">
									<h4>{{docFaq.sector}}</h4>
									<div class="pb-5" v-for="content in docFaq.contents" :key="content.id" v-html="content.details"></div>
								</div>
							</li> -->
							<!-- <li class="pb-5" v-for="docFaq in displayFaq" :key="docFaq.id">
								<h4>{{docFaq.sector}}</h4>
								<div class="pb-5" v-for="content in docFaq.contents" :key="content.id" v-html="content.details"></div>
							</li> -->
							<li class="pb-5 hide" v-for="(docFaq, index) in $t('docFaqs')" :class="{show: docFaqs[index].visible}" :id="docFaqs[index].sector" :key="docFaq.id">
								<h4>{{docFaq.sector}}</h4>
								<div class="pb-5" v-for="content in docFaq.contents" :key="content.id" v-html="content.details"></div>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
//import axios from 'axios'


export default {
	name: 'HelpCenter',
	data() {
		return {
			docFaqs: [
				{
					visible: true,
					sector: "General questions",
				},
				{
					visible: false,
					sector: "Getting started",
				},
				{
					visible: false,
					sector: "Affiliate & Commissions",
				},
				{
					visible: false,
					sector: "Common troubleshoots",
				},
				{
					visible: false,
					sector: "Transaction",
				},
				{
					visible: false,
					sector: "Understanding our trade icons",
				},
				{
					visible: false,
					sector: "Self Define Setting",
				},
				{
					visible: false,
					sector: "Others",
				}
			],
		}
	},
	/*created(){
		axios
			.get('http://localhost:3000/docFaqs')
			.then(response => {
				this.docFaqs = response.data
				console.log(response.data)
			})
			.catch(error => {
				console.log('There was an error: ' + error.response)
			})
	},*/
	computed: {
		/*displayFaq: function () {
			//return this.docFaqs.filter(docFaq => docFaq.visible)
			// return this.docFaqs.filter( function(docFaq) {
			// 	return docFaq.visible
			// })
		}*/
	},
	methods: {
		toggleSector(index){
			for (var i = 0; i<=this.docFaqs.length - 1; i++) {
				this.docFaqs[i].visible = false
			}
			this.docFaqs[index].visible = true
		},
	}
}
</script>
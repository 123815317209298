<template>
<div class="wrapperPopUp" :v-if="exchangeShow">
  <div class="popUp popUpSmall">
    
    <!-- <div class="btnPopUpCloseAlt"><a href="#">X</a></div> -->
    <div class="popUpWrapperContent">
      <div class="btnPopUpClose">
        <a href="javascript:;" @click="popExchangeClose">
          <iconClose class="w-6 inline" />
        </a>
      </div>
      <h4 class="pt-5">Get an exchange account</h4>
      <ul class="exchangeWCap flex justify-center space-x-4">
        <li class="flex-initial" v-for="exchangeCenter in exchangeCenters" :key="exchangeCenter.id">
          <a class="underline hover:no-underline px-6 py-4" :href="exchangeCenter.url" target="_blank">
            <img :src="exchangeCenter.img" :alt="exchangeCenter.name" :title="exchangeCenter.name" />
            <p>Get a {{exchangeCenter.name}} Account</p>
          </a>
        </li>
      </ul>
      <hr class="divider" >
      <div class="btn-secondary w-4/12 mx-auto lg:flex-initial">
        <a href="javascript:;" @click="popExchangeClose">OK</a>
      </div>
    </div>

  </div>
  <div class="overlayClose" @click="popExchangeClose"></div>
</div>

</template>

<script>
import iconClose from '@/assets/images/icon-close.svg?inline'
export default {
  name: 'popUp-exchange',
  props:["exchangeCenters", "exchangeShow"],
  components: {iconClose},
  data () {
    return {
    }
  },
  methods: {
    mobClose: function(){
      this.$emit('mobClose', function(){
        //return !mobMenuShow
      });
    },
    popExchangeClose: function(){
      this.$emit('popExchangeClose', function(){
        return !this.exchangeShow
      });
    }
  },
  
}
</script>
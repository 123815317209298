<template>
	<div class="wrapperContent">
		<div class="wrapperUpperFold">
			<div class="upperFoldContent"
				v-rellax="rellax"
				data-rellax-speed="-0.5">
				<section>
					<div class="lg:grid-cols-2 lg:pb-0 lg:gap-4 md:grid-cols-2 m-auto grid pb-5 gap-2">
						<div class="flex flex-col justify-center flex-row">
							<h1 class="lg:text-left md:text-left text-center" v-html="$t('landingMsg')"></h1>
							<div class="heroCta lg:flex lg:justify-start justify-center grid grid-cols-2 gap-2">
								<div class="btn-secondary lg:flex-initial">
									<a :href="appUrl" class="text-center" target="_blank">{{$t('StartNow')}}</a>
								</div>
								<div class="btn-transparent lg:flex-initial">
									<router-link :to="`/${$i18n.locale}`+ `/strategy`" custom v-slot="{navigate}">
										<a class="text-center" @click="navigate" @keypress.enter="navigate" role="link">{{$t('FindOutMore')}}</a>
									</router-link>
								</div>
							</div>
							<div class="lg:text-left text-center pt-5 hidden"><span class="inline-block align-middle lg:mr-2 mr-1"><fsaSecurity class="lg:w-6 w-4 h-auto" /></span><p class="lg:text-sm text-xs inline-block align-middle">{{$t('fsaLine')}}</p></div>
						</div>
						<div class="flex flex-col justify-center">
							<img class="lg:w-full sm:w-full xs:w-full w-4/6 m-auto" src="@/assets/images/whaledoo_hero.svg" :alt="$t('slogan')" />
						</div>
					</div>
				</section>
			</div>
			<div class="wrapperExchangeCenter"
				v-rellax="rellax"
				data-rellax-speed="0.5">
				<ul class="flex justify-center">
					<li class="flex-initial" v-for="exchangeCenter in exchangeCenters" :key="exchangeCenter.id">
						<a :href="exchangeCenter.url" target="_blank">
							<img :src="exchangeCenter.img" :alt="exchangeCenter.name" :title="exchangeCenter.name" />
						</a>
					</li>
				</ul>
			</div>
			<div class="upperFoldBg">
				<bgUpperFold />
			</div>
		</div>

		<div class="wrapperContentSection">
			<section>
				<div class="lg:w-10/12 lg:grid lg:grid-cols-10 lg:gap-4 lg:pb-0 pb-5 m-auto w-12/12 flex flex-col">
					<div class="lg:col-span-4 lg:w-full m-auto w-4/6"
						v-rellax="rellax"
						data-rellax-speed="-0.5"
						data-rellax-xs-speed="-0.3">
						<img class="lg:w-full md:w-8/12 w-full m-auto" src="@/assets/images/dictate_the_right_time.svg" :alt="$t('intro_title_1')"/>
					</div>
					<div class="lg:pt-0 pt-5 col-span-6 flex flex-col justify-center">
						<h4 class="lg:text-left lg:pt-0 lg:pb-0 pt-10 pb-2 text-center">{{$t('intro_title_1')}}</h4>
						<p class="lg:text-left text-center">{{$t('intro_dec_1')}}</p>
					</div>
				</div>

				<div class="lg:w-10/12 lg:grid lg:grid-cols-10 lg:gap-4 lg:pb-0 pb-5 m-auto w-12/12 flex flex-col rtl">
					<div class="lg:col-span-4 lg:w-full m-auto w-4/6"
						v-rellax="rellax"
						data-rellax-speed="-0.5"
						data-rellax-xs-speed="-0.3">
						<img class="lg:w-full md:w-8/12 w-full m-auto" src="@/assets/images/power_of_crypto.svg" :alt="$t('intro_title_2')" />
					</div>
					<div class="lg:pt-0 pt-5 col-span-6 flex flex-col justify-center ltr">
						<h4 class="lg:text-left lg:pt-0 lg:pb-0 pt-10 pb-2 text-center">{{$t('intro_title_2')}}</h4>
						<p class="lg:text-left text-center">{{$t('intro_dec_2')}}</p>
					</div>
				</div>
			</section>
		</div>
		<div class="wrapperContentSection whiteSection lg:my-20 my-0">
			<section>
				<div class="lg:py-20 col-span-6 flex flex-col justify-center">
					<h4 class="lg:pt-0 lg:pb-0 pt-10 pb-2 text-center">{{$t('partnership_title')}}</h4>
					<p class="text-center" v-html="$t('partnership_desc')"></p>
					<div class="lg:grid lg:grid-cols-11 lg:pt-10 lg:space-x-0 flex space-x-4 pt-5 justify-center">
						<a target="_blank" href="https://www.huobi.com/" class="lg:m-auto lg:w-full h-auto lg:col-start-5 w-1/4"><img class="m-auto" src="@/assets/images/logo_ec_huobi.svg" alt="Huobi Global" /></a>
						<a target="_blank" href="https://www.binance.com/" class="lg:m-auto lg:w-full h-auto lg:col-start-7 w-1/4"><img class="m-auto" src="@/assets/images/logo_ec_binance.svg" alt="Binance" /></a>
					</div>
				</div>
			</section>
			<div class="bgPerspective">
				<div class="relative h-full">
					<div class="bgPatternDot"></div>
					<div class="bgTriangle tri-up"></div>
					<div class="bgTriangle tri-down"></div>
				</div>
			</div>
		</div>
		<div class="wrapperContentSection">
			<section>
				<div class="lg:pt-0">
					<h4 class="lg:pt-0 lg:pb-10 pt-10 pb-2 text-center">{{$t('usp_title')}}</h4>
					<ul class="grid lg:grid-cols-3 grid-cols-2 items-center uspContainer">
						<li class="lg:p-10 px-4 py-6 flex flex-col h-full items-center justify-center bg-white">
							<img class="lg:w-1/3 lg:h-40 w-1/2 h-30" src="@/assets/images/icon_fast_transfer.svg" :alt="$t('usp_icon_1')" />
							<p class="col-span-3 pt-5 text-center">{{$t('usp_icon_1')}}</p>
						</li>
						<li class="lg:p-10 px-4 py-6 flex flex-col h-full items-center justify-center bg-white">
							<img class="lg:w-1/3 lg:h-40 w-1/2 h-30" src="@/assets/images/icon_24hour.svg" :alt="$t('usp_icon_2')" />
							<p class="col-span-3 pt-5 text-center">{{$t('usp_icon_2')}}</p>
						</li>
						<li class="lg:p-10 px-4 py-6 flex flex-col h-full items-center justify-center bg-white">
							<img class="lg:w-1/3 lg:h-40 w-1/2 h-30" src="@/assets/images/icon_free.svg" :alt="$t('usp_icon_3')" />
							<p class="col-span-3 pt-5 text-center">{{$t('usp_icon_3')}}</p>
						</li>
						<li class="lg:p-10 px-4 py-6 flex flex-col h-full items-center justify-center bg-white">
							<img class="lg:w-1/3 lg:h-40 w-1/2 h-30" src="@/assets/images/icon_chart.svg" :alt="$t('usp_icon_4')" />
							<p class="col-span-3 pt-5 text-center">{{$t('usp_icon_4')}}</p>
						</li>
						<li class="lg:p-10 px-4 py-6 flex flex-col h-full items-center justify-center bg-white">
							<img class="lg:w-1/3 lg:h-40 w-1/2 h-30" src="@/assets/images/icon_invest_amount.svg" :alt="$t('usp_icon_5')" />
							<p class="col-span-3 pt-5 text-center">{{$t('usp_icon_5')}}</p>
						</li>
						<li class="lg:p-10 px-4 py-6 flex flex-col h-full items-center justify-center bg-white">
							<img class="lg:w-1/3 lg:h-40 w-1/2 h-30" src="@/assets/images/icon_segregated.svg" :alt="$t('usp_icon_6')" />
							<p class="col-span-3 pt-5 text-center">{{$t('usp_icon_6')}}</p>
						</li>
					</ul>
				</div>
			</section>
			<div class="uspTexture textureSection"></div>
		</div>
		<div class="wrapperContentSection textureSection">
			<section>
				<h3 class="lg:text-left text-center lg:hidden block">{{$t('accordion_title')}}</h3>
				<div class="lg:grid lg:grid-cols-2 lg:p-8 w-12/12 m-auto flex flex-col flex-col-reverse space-y-reverse space-y-4 wrapperAccordion">
					<div class="flex flex-col justify-center">
						<h3 class="lg:text-left lg:pt-0 pt-5 text-center lg:block hidden">{{$t('accordion_title')}}</h3>
						<ul class="flex space-y-4 flex-col">
							<li class="flex space-x-4 cursor-pointer" @click="displaySafe">
								<div class="accordionRadio flex-shrink-0 flex-initial" :class="{selected:showSafe}"></div>
								<div class="accordionDetails flex-initial">
									<h5 class="pb-0">{{$t('accordion_cap_1')}}</h5>
									<p class="hide" :class="{show:showSafe }">{{$t('accordion_dec_1')}}</p>
								</div>
							</li>
							<li class="flex space-x-4 cursor-pointer" @click="displaySegregated">
								<div class="accordionRadio flex-shrink-0 flex-initial" :class="{selected:showSegregated}"></div>
								<div class="accordionDetails flex-initial">
									<h5 class="pb-0">{{$t('accordion_cap_2')}}</h5>
									<p class="hide" :class="{show:showSegregated}">{{$t('accordion_dec_2')}}</p>
								</div>
							</li>
							<li class="flex space-x-4 cursor-pointer" @click="displaySecure">
								<div class="accordionRadio flex-shrink-0 flex-initial" :class="{selected:showSecure}"></div>
								<div class="accordionDetails flex-initial">
									<h5 class="pb-0">{{$t('accordion_cap_3')}}</h5>
									<p class="hide" :class="{show:showSecure}" >{{$t('accordion_dec_3')}}</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="flex flex-col justify-center relative">
						<img class="lg:w-10/12 w-4/6 m-auto" :src="highSecureImg" :alt="$t('accordion_title')" />
					</div>
				</div>
			</section>
			<div class="bgTexture"></div>
		</div>
		<div class="wrapperContentSection extSection">
			<section>
				<div class="lg:w-10/12 lg:grid lg:grid-cols-10 lg:gap-4 lg:pb-0 w-full pb-5 m-auto w-12/12 flex flex-col">
					<div class="lg:col-span-6 lg:w-full m-auto lg:text-left text-center">
						<h3 v-html="$t('cta_shoutout')"></h3>
						<p v-html="$t('cta_shoutDetails')"></p>
						<br>
						<p>{{$t('cta_shoutDetails2')}}
							<router-link :to="`/${$i18n.locale}`+ `/help-center`" custom v-slot="{navigate}">
								<a class="lg:text-left text-center" href @click="navigate" @keypress.enter="navigate" role="link">
									{{$t('cta_shout_link')}}
								</a>
							</router-link>
						</p>
					</div>
					<div class="lg:col-span-4 lg:w-full lg:pt-0 lg:flex-row lg:space-y-0 h-4/6 flex flex-col space-y-4 gap-4 pt-5 w-8/12 m-auto">
						<div class="neuBlock priceBox flex flex-col justify-center">
							<span class="priceText text-center">{{$t('annualFee')}}</span>
							<p>{{$t('annualFee_desc')}}</p>
						</div>
						<div class="neuBlock priceBox flex flex-col justify-center">
							<span class="priceText text-center">{{$t('performanceFee')}}</span>
							<p>{{$t('performanceFee_desc')}}</p>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div class="wrapperContentSection">
			<section>
				<h4 class="lg:pt-0 lg:pb-0 pt-10 pb-2 text-center">{{$t('app_title')}}</h4>
				<p class="text-center" v-html="$t('app_desc')"></p>
				<div class="lg:w-6/12 w-full m-auto py-5">
					<div class="flex flex-col justify-center relative">
						<img class="lg:w-10/12 w-4/6 m-auto" src="@/assets/images/easy_assistance_to_rely.svg" alt="An easy assistance you can rely on" />
						<div class="absolute bottom-0 w-full">
							<ul class="lg:w-full w-10/12 flex m-auto justify-center space-x-4">
								<li class="flex-initial">
									<a :href="apkLink" target="_blank">
										<img src="@/assets/images/apk.png" srcset="@/assets/images/apk.png 1x, @/assets/images/apk@2x.png 2x" alt="Google Play" />
									</a>
								</li>
								<li class="flex-initial">
									<a :href="iosLink" target="_blank">
										<img src="@/assets/images/ios.png" srcset="@/assets/images/ios.png 1x, @/assets/images/ios@2x.png 2x" alt="App Store" />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</div>
		<section-lxBacktest></section-lxbacktest>
		<div class="wrapperContentSection featuredSection">
			<section>
				<h3 class="text-center">Featured In</h3>
				<div class="featuredLogo pt-5 pb-5">
					<ul class="lg:flex lg:gap-6 grid grid-cols-2 gap-8 items-center">
						<li v-for="featuredPlatform in featuredPlatforms" :key="featuredPlatform.id">
							<a :href="featuredPlatform.url" target="_blank">
								<img :src="featuredPlatform.img" :alt="featuredPlatform.alt">
							</a>
						</li>
					</ul>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import bgUpperFold from '@/assets/images/bg_upperfold.svg?inline';
import fsaSecurity from '@/assets/images/icon_fsa.svg?inline';
import sectionLxBacktest from '@/components/sectionLxBacktest';

import Vue from 'vue'
import VueRellax from 'vue-rellax'

Vue.use(VueRellax)

export default {
	name: 'home',
	props: [
		"exchangeCenters", "exchangeShow", "featuredPlatforms",
		"apkLink", "iosLink",
		"appUrl"
	],
	components: {
		sectionLxBacktest,
		bgUpperFold,
		fsaSecurity
	},
	data() {
		/*landingMsg: "",intro_title_1: "", intro_title_2: "", intro_dec_1: "", intro_dec_2: "",usp_title: "", usp_icon_1: "", usp_icon_2: "", usp_icon_3: "", usp_icon_4: "", accordion_title: "", accordion_cap_1: "", accordion_dec_1: "", accordion_cap_2: "", accordion_dec_2: "", accordion_cap_3: "", accordion_dec_3: "", cta_shoutout: "", cta_shoutDetails: "", callToAction: "",*/
		return {
			rellax: {
				speed: -2,
				breakpoints:[640, 768, 1024]
			},
			showSafe: true,
			showSegregated: false,
			showSecure: false,
			highSecureImg: require("@/assets/images/secure_regulated.svg")
		}
	},
	computed: {},
	methods: {
		displaySafe: function(){
			this.showSafe = this.showSegregated = this.showSecure  = false;
			this.showSafe = true;
			this.highSecureImg = require("@/assets/images/secure_regulated.svg")
		},
		displaySegregated: function(){
			this.showSafe = this.showSegregated = this.showSecure  = false;
			this.showSegregated = true;
			this.highSecureImg = require("@/assets/images/secure_segregated_account.svg")
		},
		displaySecure: function(){
			this.showSafe = this.showSegregated = this.showSecure  = false;
			this.showSecure = true;
			this.highSecureImg = require("@/assets/images/secure_server.svg")
		}
		/*langTranslate: function(translate) {
			this._data[translate] = this.$t(translate);
		},
		mountedLangUpdate: function(){
			const translate = [
				"landingMsg", "intro_title_1", "intro_title_2", "intro_dec_1", "intro_dec_2",
				"usp_title", "usp_icon_1", "usp_icon_2", "usp_icon_3", "usp_icon_4",
				"accordion_title", "accordion_cap_1", "accordion_dec_1", "accordion_cap_2", "accordion_dec_2", "accordion_cap_3", "accordion_dec_3",
				"cta_shoutout", "cta_shoutDetails", "callToAction"
			];
			for (var i = 0; i<=translate.length - 1; i++) {
				this.langTranslate(translate[i])
			}
		},*/
	},
	mounted: function(){
		//this.mountedLangUpdate();
	},
	metaInfo: {
		meta: [
			{ name: 'keywords', content: 'whaledoo, crypto, trading, robot, bot, robo'}
		]
	}
}
</script>

<template>
	<div class="wrapperContent">
		<div class="wrapperContentSection docSection">
			<section>
				<div class="docHeader lg:w-8/12 w-full flex flex-col m-auto">
					<div class="flex flex-col justify-center flex-row">
						<h1 class="text-center">{{$t('privacy_policy')}}</h1>
					</div>
				</div>
			</section>
		</div>
		<div class="wrapperContentSection">
			<section>
				<div class="wrapperTnc block m-auto lg:w-10/12 w-12/12">
					<small class="block mb-8">{{$t('privacy_policy_update_time')}}</small>
					<h5>{{$t('pnp_main_title')}}</h5>
					<ul>
						<li v-for="list in $t('privacy_policy_listing')" :key="list.id">
							<div v-html="list.title" class="mb-4"></div>
							<div v-html="list.content"></div>
						</li>
					</ul>
				</div>
			</section>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Privacy_Policy',
	data() {
		return {

		}
	}
}
</script>

<template>
  <header class="lg:relative w-full h-fit fixed z-10" :class="{mobMenuShow: mobMenuShow}">
    <section>
      <div class="wrapperHeader lg:flex grid grid-cols-10 gap-2 items-center justify-between w-full">
        <!-- mobile menu !-->
        <div class="logo lg:col-auto col-span-5">
          <router-link :to="`/${$i18n.locale}`" custom v-slot="{navigate}">
            <a @click="navigate" @keypress.enter="navigate" role="link">
              <logoSvg class="w-full h-auto" />
            </a>
          </router-link>
        </div>
        <div class="lg:hidden md:col-span-2 md:col-start-8 col-span-3 col-start-7 h-10 w-fit">
          <div class="langSelect items-center w-full h-full cursor-pointer">
             <langSelect class="select"
                :options="[{'display':'EN', 'value': 'en'},{'display': '简体', 'value':'zh'}]" :default= defaultLang
              ></langSelect>
          </div>
        </div>
        <div class="wrapperBtnMobMenu lg:hidden col-start-10 col-span-1">
          <div class="btnMobMenu">
            <a v-if="!mobMenuShow" href="javascript:;" @click="mobOpen" @keypress.enter="mobOpen">
              <img  src="@/assets/images/icon-menu.svg" alt="" />
            </a>
            <a v-else href="javascript:;" @click="mobClose" @keypress.enter="mobClose">
              <img  src="@/assets/images/icon-close.svg" alt="" />
            </a>
          </div>
        </div>
        <nav class="hidden lg:block">
          <ul class="hidden lg:flex">
            <li v-for="mainNav in ($t('mainNavs'))" :key="mainNav.id">
              <div class="btn-primary">
                <!-- <router-link :to="`/${$i18n.locale}`+ mainNav.url" exact v-html=" $t('mainNav.pageTitle') "></router-link> -->
                <router-link :to="`/${$i18n.locale}`+ mainNav.url" exact>{{ mainNav.pageTitle }}</router-link>
              </div>
            </li>
            <li><div class="btn-secondary"><a :href="appUrl" target="_blank">{{$t('callToAction')}}</a></div></li>
            <li>
              <div class="w-full h-full">
                <div class="langSelect items-center w-full h-full cursor-pointer">
                   <langSelect class="select"
                      :options="[{'display':'EN', 'value': 'en'},{'display': '简体', 'value':'zh'}]" :default= defaultLang
                    ></langSelect>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  </header>
</template>

<script>
import langSelect from "@/components/langSelect.vue";
import logoSvg from '@/assets/images/logo.svg?inline';

export default {
  name: 'app-header',
  props: [
    // $emit
    'mobMenuShow',
    'mainNavs',
    'appUrl'
  ],
  data () {
    return {
      defaultLang: this.$i18n.locale == 'en' ? this.$i18n.locale == 'zh' ? 'default' : 'EN' : '简体'
    }
  },
  components: {
    logoSvg, langSelect
  },
  methods: {
    onChange: function(event) {
      let locale = event.target.value
      this.$i18n.locale = locale
      this.$router.push({
        params: { lang: locale }
      })
    },
    mobOpen: function(){
      this.$emit('mobOpen', function(){
        //return mobMenuShow;
      });
    },
    mobClose: function(){
      this.$emit('mobClose', function(){
        //return mobMenuShow;
      });
    }
  }
}
</script>

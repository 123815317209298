<template>
  <div class="wrapperContentSection backtestSection" id="backtest">
      <section>
          <h3 class="text-center">{{$t('backTest_title')}}</h3>
          <div class="wrapperBacktestInput lg:grid lg:grid-cols-2 lg:w-8/12 lg:gap-8 m-auto w-full flex flex-wrap flex-row justify-between items-center gap-4 pb-5">
              <div class="w-full">
                  <div class="flex flex-wrap flex-row justify-between items-center pb-5">
                      <strong class="tracking-wide">{{$t('startingFund')}} (USDT)</strong>
                      <p class="tracking-wide" id="initialValue">$0</p>
                  </div>
                  <!-- <vue-slider v-model="slideInitial" :min="1" :max="50000" :interval="0.01" class="slider w-full" id="slideInitial"></vue-slider> -->
                  <input type="range" min="1" max="50000" value="1" class="slider w-full" id="slideInitial" />
              </div>
              <div class="w-full">
                  <div class="flex flex-wrap flex-row justify-between items-center pb-5">
                      <strong class="tracking-wide">{{$t('monthlyTopUp')}} (USDT)</strong>
                      <p class="tracking-wide" id="monthlyValue">$0</p>
                  </div>

                  <input type="range" min="0" max="50000" value="0" class="slider w-full" id="slideMonthly" />
              </div>
          </div>

          <div class="lg:w-8/12 pt-5 flex justify-center mx-auto w-full relative">
              <div class="absolute left-0" id="legend-container"></div>
              <div class="backtestTable absolute bg-gray-700 text-white w-1/2" id="tooltip">
                  <table></table>
              </div>
              <!-- <BackTestChart :slideInitial="slideInitial"></BackTestChart> -->
              <BackTestChart></BackTestChart>
          </div>


          <div class="block mb-8 mt-8">
              <ul class="lg:w-8/12 footnote w-full mx-auto text-xs">
                  <li>* {{$t('asterisk_1')}}</li>
                  <li>** {{$t('asterisk_2')}}</li>
                  <li>{{$t('asterisk_0')}}</li>
              </ul>
          </div>
      </section>
  </div>
  
   
</template>

<script>
import BackTestChart from '@/components/backtestchart'

/*import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'*/

export default {
  name: 'section-lxBacktest',
  components: {
    BackTestChart,
    /*VueSlider*/
  },
  data(){
    return{
      /*backTest_title: "", startingFund: "", monthlyTopUp: "", asterisk_1: "", asterisk_2: "", asterisk_0: ""*/
      /*slideInitial: 1500,*/
    }
  },
  methods: {},
  mounted: function(){}
}
</script>


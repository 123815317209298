<template>
  <div class="wrapperCustomSelect" :tabindex="tabindex" @blur="open = false">
    <div class="customSelect" :class="{ open: open }" :data="value" @click="open = !open">
      <div class="svg-baseSurface flex pr-1"><iconLang class="m-auto w-6" alt="Select Langauge" /></div>
      <div class="flex selected">
        {{ selected }}
      </div>
    </div>
    <ul class="items" :class="{ selectHide: !open }" @click="onLangChange(value)">
      <li v-for="(option, i) of options" :value="option.value" :key="i"
        @click="selected = option.display; value = option.value; open = false;
        $emit('input', option.display);">
          {{ option.display }}
        </li>
    </ul>
  </div>
</template>

<script>
import iconLang from '@/assets/images/language.svg?inline'

export default {
  name: 'langSelect',
  components: {iconLang},
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
      value: this.default
    };
  },
  methods: {
    onLangChange: function(value){
      let locale = value
      this.$router.push({
        params: { lang: locale }
      })
    }
  },
  mounted() {
    this.$emit("input", this.selected);
  },
};
</script>
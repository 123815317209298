<template>
  <footer>
    <section>
      <div class="flex lg:flex-row w-full flex-col justify-space pb-5 m-auto">
        <div class="flex flex-col lg:w-6/12 w-full pb-5">
          <h2 class="lg:text-left text-center">{{$t('getInTouch')}}</h2>
          <ul class="flex flex-row space-x-4 lg:justify-start justify-center">
            <li>
              <a :href="whatsappLink" target="_blank"><div class="icon icon-whatsapp"><iconWhatsapp :alt="'Whatsapp'" /></div></a>
            </li>
            <li><a :href="telegramLink" target="_blank"><div class="icon icon-telegram"><iconTelegram :alt="'Telegram'" /></div></a></li>
            <li><a :href="wechatLink" target="_blank"><div class="icon icon-wechat"><iconWechat :alt="'Wechat'" /></div></a></li>
            <li><a :href="MessengerLink" target="_blank"><div class="icon icon-messenger"><iconMessenger :alt="'Facebook Messenger'" /></div></a></li>
          </ul>
        </div>
        <div class="flex lg:w-6/12 w-full justify-end">
          <p class="lg:text-left text-center" v-html="$t('footerCopy')"></p>
        </div>
      </div>

      <hr class="pb-10" />

      <div class="lg:flex-row lg:space-y-0 flex flex-col-reverse justify-between w-full items-center space-y-reverse space-y-4">
        <small class="lg:text-left block text-center">
            {{$t('copyrightReserved')}} |
            <router-link :to="`/${$i18n.locale}`+ `/terms-and-conditions`" class="txtLink-onDark" custom v-slot="{navigate}">
              <a class="text-center" @click="navigate" @keypress.enter="navigate" role="link">{{$t('tnc')}}</a>
            </router-link> |
            <router-link :to="`/${$i18n.locale}`+ `/privacy-policy`" class="txtLink-onDark" custom v-slot="{navigate}">
                <a class="text-center" @click="navigate" @keypress.enter="navigate" role="link">{{$t('privacy_policy')}}</a>
            </router-link>
            <!-- {{$t('fsaLine2')}} -->
        </small>
        <div class="flex flex-row justify-center space-x-4">
          <a :href="apkLink" target="_blank">
            <img class="w-full m-auto" src="@/assets/images/apk.png" srcset="@/assets/images/apk.png 1x, @/assets/images/apk@2x.png 2x" alt="Google Play" />
          </a>
          <a  :href="iosLink" target="_blank">
            <img class="w-full m-auto" src="@/assets/images/ios.png" srcset="@/assets/images/ios.png 1x, @/assets/images/ios@2x.png 2x" alt="App Store" />
          </a>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import iconWhatsapp from '@/assets/images/icon-whatsapp.svg?inline';
import iconTelegram from '@/assets/images/icon-telegram.svg?inline';
import iconWechat from '@/assets/images/icon-wechat.svg?inline';
import iconMessenger from '@/assets/images/icon-messenger.svg?inline';

export default {
  name: 'app-footer',
  props:[
    "apkLink", "iosLink",
    "whatsappLink", "telegramLink", "MessengerLink", "wechatLink"
  ],
  components: {
    iconWhatsapp, iconTelegram, iconWechat, iconMessenger
  },
  data() {
    return {
      /*getInTouch: "", footerCopy: "", copyrightReserved: "", tnc: ""*/
    }
  },
  mounted: function(){}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

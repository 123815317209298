<template>
  <div id="app">
    <!-- pop up !-->
    <transition name="fadeSlideLeft">
      <mob-menu
        v-if="mobMenuShow"
        :mainNavs="mainNavs"
        @mobClose="mobMenuClose($event)"
      ></mob-menu>
    </transition>

    <transition name="fadeSlideLeft">
      <popUp-exchange
        v-if="exchangeShow"
        @popExchangeClose="exchangeClose($event)"
        @openExchange="exchangeOpen($event)"
        :exchangeCenters="exchangeCenters"
      ></popUp-exchange>
    </transition>
    <transition name="fadeSlideLeft"><div class="overlayBackgroundMenu" v-if="gOverlayMobMenuOpened"></div></transition>
    <transition name="fadeSlideLeft"><div class="overlayBackground" v-if="gOverlayOpened"></div></transition>
    <!-- end of pop up !-->
    <app-header
      :appUrl="appUrl"
      :mainNavs="mainNavs"
      :mobMenuShow="mobMenuShow"
      @mobOpen="mobMenuOpen($event)"
      @mobClose="mobMenuClose($event)"
    ></app-header>

    <router-view
      @popExchangeClose="exchangeClose($event)"
      @openExchange="exchangeOpen($event)"

      :appUrl="appUrl"
      :apkLink="apkLink"
      :iosLink="iosLink"
      :exchangeCenters="exchangeCenters"
      :featuredPlatforms="featuredPlatforms"
    />

    <app-footer
      :whatsappLink="whatsappLink"
      :telegramLink="telegramLink"
      :wechatLink="wechatLink"
      :MessengerLink="MessengerLink"
      :apkLink="apkLink"
      :iosLink="iosLink"
    ></app-footer>
  </div>
</template>

<script>
// pop up
import mobMenu from '@/components/mobMenu'
import popUpExchange from '@/components/popUp'

import appHeader from '@/components/appHeader'
import appFooter from '@/components/appFooter'

export default {
  name: 'App',
  components: {
    // pop up
    mobMenu,
    popUpExchange,
    // end of pop up
    appHeader,
    appFooter
  },
  data(){
    return{
      gOverlayOpened: false,
      exchangeShow: false,
      gOverlayMobMenuOpened: false,

      mobMenuShow: false,
      mainNavs: [
        {
          url: '/strategy',
          pageTitle: 'Strategy'},
        {
          url: '/affiliate',
          pageTitle: 'Affiliate'},
        {
          url: '/help-center',
          pageTitle: 'Help Center'}
      ],
      appUrl: "https://app.whaledoo.com/",
      apkLink: "https://play.google.com/store/apps/details?id=io.whaledoo",
      iosLink: "https://www.apple.com/my/app-store/",
      whatsappLink: "https://wa.link/n97oex",
      telegramLink: "https://t.me/+IqLqqrmI5kQwYWE1",
      wechatLink: "weixin://dl/chat?WhaledooChat",
      MessengerLink: "https://www.facebook.com/Whaledoo.global",
      exchangeCenters: [
        {
          name: "Huobi",
          img: require("@/assets/images/Huobi-logo.png"),
          url: "https://www.huobi.com/en-us/topic/double-reward/?invite_code=mp273223"
        },
        {
          name: "Binance",
          img: require("@/assets/images/Binance-logo.png"),
          url: "https://accounts.binance.com/en/register?ref=SACSI1LP"
        },
        /*{
          name: "Luno",
          img: require("@/assets/images/Luno-logo.png"),
          url: "https://accounts.binance.com/en/register?ref=SACSI1LP"
        }*/
      ],
      featuredPlatforms: [
        {
          img: require("@/assets/images/logo_huobi_global.png"),
          url: 'https://www.instagram.com/p/CZJa7V4JsOy/?utm_medium=copy_link',
          alt: 'Huobi Global'},
        {
          img: require("@/assets/images/logo_medium.png"),
          url: 'https://medium.com/@Whaledoo/cryptocurrency-is-chaotic-and-hard-to-manage-heres-what-you-can-do-instead-e39a235ced3c',
          alt: 'Medium'},
        {
          img: require("@/assets/images/logo_ecibank.png"),
          url: 'https://www.ecibank.com/',
          alt: 'EC Investment Bank'},
        {
          img: require("@/assets/images/logo_binance.png"),
          url: 'https://www.binance.com/',
          alt: 'Binance'}
        /*{
          img: require("@/assets/images/logo_labuan_fsa.png"),
          url: 'https://www.labuanfsa.gov.my/',
          alt: 'Labuan Financial Services Authority'}*/
      ]
    }
  },
  methods: {
    // mobile menu
    mobMenuClose:function(){
      this.mobMenuShow = false
      this.gOverlayMobMenuOpened = false
    },
    mobMenuOpen:function(){
      this.mobMenuShow = true
      this.gOverlayMobMenuOpened = true
    },
    // pop up exchange center
    // pop up promotion
    exchangeClose: function(){
      this.exchangeShow = false
      this.gOverlayOpened = false
    },
    exchangeOpen: function(){
      this.exchangeShow = true
      this.gOverlayOpened = true
    }
    // end of mobile menu
  },
  mounted: function(){
    // let externalChart = document.createElement('script')
    // externalChart.setAttribute('src', 'https://cdn.jsdelivr.net/npm/chart.js')
    // document.body.appendChild(externalChart)
  },
  watch: {
    '$route' () {
      this.mobMenuShow = false
      this.gOverlayMobMenuOpened = false
      document.getElementsByTagName("html")[0].lang = this.$i18n.locale;
    }
  },
  metaInfo: {
    title: 'Whaledoo | Trade with confidence',
    titleTemplate: 'Whaledoo | Trade with confidence',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Whaledoo understands your frustration at the non-stop ups and downs of the cryptocurrencies market, so we’ll do exactly what it takes to turn these trends into an opportunity for you to make profits.'},
      { name: 'keywords', content: 'whaledoo, crypto, trading, robot, bot, robo'},
      { name: 'copyright', content: 'Whaledoo'},
      { name: 'author', content: 'Whaledoo'},
      { name: 'category', content: '#'},
      { property: 'og:title', content: 'Whaledoo | Trade with confidence'},
      { property: 'og:site_name', content: 'Whaledoo'},
      { property: 'og:url', content: 'https://whaledoo.com'},
      { property: 'og:image', content: 'https://s.whaledoo.com/assets/images/whaledoo_social.jpg'},
      { property: 'twitter:description', content: 'Whaledoo understands your frustration at the non-stop ups and downs of the cryptocurrencies market, so we’ll do exactly what it takes to turn these trends into an opportunity for you to make profits.'},
      { property: 'twitter:image', content: 'https://s.whaledoo.com/assets/images/whaledoo_social.jpg'}
    ]
  }
}
</script>
<style lang="scss">
  #app {
    // header {
    //   display: none;
    // }
  }
</style>
